<template>
  <div class="charge-card">
    <DynamicCompose
      :max="30"
      :value="value"
      :createItem="createItem"
      @input="$emit('input', $event)">
      <template #default="{ data, id, handleDelete, deleteDisabled }">
        <ykc-form class="charge-card-form" :model="data" :rules="data.rules">
          <ykc-icon
            name="reduce"
            :class="{ disabled: deleteDisabled }"
            @click="deleteRow({ deleteDisabled, handleDelete, id })"></ykc-icon>
          <ykc-form-item prop="cardId">
            <ykc-input v-model="data.cardId" @change="data.success = false">
              <template #prepend>卡号</template>
            </ykc-input>
          </ykc-form-item>
        </ykc-form>
      </template>
      <template #add="{ handleAdd, reachMax }">
        <div class="charge-card-add" @click="addItem({ handleAdd, reachMax })">
          <ykc-icon name="add"></ykc-icon>
          <span>添加充电卡</span>
        </div>
      </template>
    </DynamicCompose>
  </div>
</template>

<script>
  import DynamicCompose from './dynamic-compose.vue';
  import regexpObj from '@/utils/regexp';

  export default {
    components: { DynamicCompose },
    props: {
      value: {
        type: Array,
        required: true,
      },
      cardIdValidator: {
        type: Function,
        default: () => {},
      },
    },
    watch: {
      value: {
        deep: true,
        immediate: true,
        handler() {
          this.initFields();
        },
      },
    },
    data() {
      return {
        cardIdList: [],
      };
    },
    methods: {
      initFields() {
        this.value.forEach(item => {
          if (!item.rules) {
            const createRules = () => {
              return {
                cardId: [
                  {
                    required: true,
                    validator: async (_, val, cb) => {
                      if (!val) {
                        return cb('请输入充电卡号');
                      }
                      if (!regexpObj.regexp.input.accountType.regexp.test(val)) {
                        return cb('请输入正确的充电卡号');
                      }
                      const exist = this.value.find(i => i !== item && i.cardId === val);
                      if (exist) {
                        return cb('用户卡号已存在');
                      }
                      if (item.success) {
                        return cb();
                      }
                      if (this.cardIdList.includes(val)) {
                        item.success = true;
                        return cb();
                      }
                      const { success, message } = await this.cardIdValidator(val);
                      item.success = success;
                      if (success) {
                        this.cardIdList.push(val);
                        return cb();
                      }
                      return cb(new Error(message));
                    },
                    trigger: 'blur',
                  },
                ],
              };
            };
            this.$set(item, 'rules', createRules());
          }
        });
      },
      deleteRow({ deleteDisabled, handleDelete, id }) {
        if (deleteDisabled) return;
        handleDelete(id);
      },
      createItem() {
        const item = {
          cardId: '',
          success: false,
        };
        return item;
      },
      validate() {
        const ykcFormVms = [...this.$el.querySelectorAll('.ykc-form.charge-card-form')].map(
          // eslint-disable-next-line
          node => node.__vue__
        );
        const promisify = item =>
          new Promise(resolve => {
            item.validate(valid => {
              resolve(valid);
            });
          });
        return new Promise(resolve => {
          Promise.all(ykcFormVms.map(promisify)).then(res => {
            resolve(res.every(valid => !!valid));
          });
        });
      },
      addItem({ handleAdd, reachMax }) {
        if (reachMax) {
          // 这里可以给出提示
          return;
        }
        this.validate().then(valid => {
          if (valid) {
            handleAdd();
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  .charge-card {
    color: inherit;
    &-form {
      display: flex;
      .ykc-icon-reduce {
        width: 30px;
        text-align: right;
        height: 16px;
        margin-top: 7px;
        margin-right: 8px;
        color: var(--theme-color-primary);
        &.disabled {
          color: #d7d7d7;
          cursor: not-allowed;
        }
      }
    }
    &-add {
      cursor: pointer;
      font-size: 12px;
      color: var(--theme-color-primary);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      .ykc-icon-add {
        width: 30px;
        text-align: right;
        margin-right: 8px;
        color: var(--theme-color-primary);
      }
    }
  }
</style>
