<!-- 营销管理 实体充电卡 离线卡管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" v-rbac="'marketing:card:offline:bind'" @click="doBind">
              绑定离线卡
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <BindDialog
      :showDrawer="showDrawer"
      @closeDrawer="showDrawer = false"
      @finish="searchTableList" />
  </scroll-layout>
</template>

<script>
  import { code } from '@/utils';
  import { chargingCardManage } from '@/service/apis';

  import BindDialog from './BindDialog.vue';

  export default {
    name: 'offlineCardManageList',
    components: {
      BindDialog,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'stationId',
            label: '电站编码',
            placeholder: '请输入电站编码',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'pileCode',
            label: '电桩编码',
            placeholder: '请输入电桩编码',
          },
        ];
      },
    },
    data() {
      return {
        showDrawer: false,
        tableData: [],

        tableColumns: [
          { label: '电站编码', prop: 'stationId', minWidth: '160px' },
          { label: '电桩编码', prop: 'pileCode', minWidth: '160px' },
          { label: '电站名称', prop: 'stationName', minWidth: '160px' },
          { label: '最近下发时间', prop: 'issuedTime', minWidth: '160px' },
          {
            label: '最近下发指令',
            prop: 'status',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const statusMap = {
                  1: '下发',
                  2: '清除',
                };
                const status = statusMap[row.status] || '——';
                return (
                  <div>
                    <span title={status}>{status}</span>
                  </div>
                );
              },
            },
          },
        ],
        tableTitle: '离线卡列表',

        tableOperateButtons: [
          {
            enabled: () => code('marketing:card:offline:detail'),
            id: '1',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.openDetail(row);
            },
          },
          {
            enabled: () => code('marketing:card:offline:issued'),
            id: '2',
            text: '重新下发',
            handleClick: (btn, { row }) => {
              this.openIssued(row);
            },
          },
          {
            enabled: () => code('marketing:card:offline:delete'),
            id: '3',
            text: '清除',
            handleClick: (btn, { row }) => {
              this.openClean(row);
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          stationId: '',
          stationName: '',
          pileCode: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        chargingCardManage
          .offQueryForPage(reqParams)
          .then(res => {
            console.log('res', res);
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 绑定离线卡
      doBind() {
        console.log('绑定离线卡');
        this.showDrawer = true;
      },
      // 详情
      openDetail(row) {
        this.$router.push({
          path: '/marketingCenter/chargingCard/offlineCardManage/detail',
          query: {
            id: row.id,
          },
        });
      },
      // 重新下发
      openIssued(row) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          type: 'success',
          showSuccessIcon: false,
          desc: '确认重新下发指令？',
          onConfirm: done => {
            done();
            chargingCardManage
              .offReissue({
                id: row.id,
              })
              .then(() => {
                this.searchTableList();
                this.$message({
                  message: '重新下发指令成功',
                  type: 'success',
                });
              });
          },
        });
      },
      // 清除
      openClean(row) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          type: 'success',
          showSuccessIcon: false,
          desc: '确认清除离线卡？',
          onConfirm: done => {
            done();
            chargingCardManage
              .offClear({
                id: row.id,
              })
              .then(() => {
                this.$message({
                  message: '清除成功',
                  type: 'success',
                });
                this.searchTableList();
              });
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
